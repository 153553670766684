<template>
  <div class="project-wrapper">
    <router-link
      class="next-project pb-8"
      :class="{
        'palette-Labels': category && category.colorPalette === 'Labels',
        'palette-Packaging': category && category.colorPalette === 'Packaging',
        'palette-Publishing': category && category.colorPalette === 'Publishing',
        'palette-CorporateIdentity': category && category.colorPalette === 'CorporateIdentity',
      }"
      :to="{name: 'SingleProject', params: {slug: _slug}}"
      :title="title"
    >
      <article class="container">
        <div class="title-wrapper">
          <h1>{{title}}</h1>
        </div>
        <div class="row image-wrapper">
          <div class="col-md-6 offset-md-6">
            <ResponsiveImage class="-mt-10" v-if="image" :data="image" />
          </div>
        </div>
      </article>
    </router-link>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import ResponsiveImage from '@/atoms/ResponsiveImage'

export default {
  name: 'NextProject',
  inheritAttrs: false,
  props: {
    _slug: { type: String },
    title: { type: String },
    index: { type: Number },
    category: { type: Object },
    image: { type: Object }
  },
  components: { ResponsiveImage },
  async mounted () {
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom+=10'
        },
        onComplete: () => {
          this.$el.classList.add('ready')
        }
      })
      this.tl.fromTo(this.$el.querySelector('h1'), { x: 50, autoAlpha: 0 }, { x: 0, autoAlpha: 1, duration: 0.5 })
      this.tl.fromTo(this.$el.querySelector('img'), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1, duration: 0.5 })
    }
  }
}
</script>

<style lang="scss" scoped>
$visible-mobile: 350px;
$visible-padding: 150px;
$visible-desktop: 400px;

.project-wrapper {
  overflow: hidden;
  height: rem($visible-mobile);

  padding-top: rem($visible-padding);

  @screen md {
    height: rem($visible-desktop);
  }
}

.next-project {
  position: relative;

  display: block;
  height: rem($visible-mobile);

  transition: transform 0.3s, background-color 0.5s, color 0.3s;
  @screen md {
    height: rem($visible-desktop);
  }

  html.desktop &:hover {
    transform: translateY(-30px);

    .image-wrapper {
      transform: translateY(-20px);
    }
  }
}

.image-wrapper {
  transition: transform 0.3s;
  @screen beforeMd {
    display: none;
  }
}

.title-wrapper {
  position: absolute;
  top: rem(16px);
  left: 50%;

  transform: translateX(-50%);
  @screen md {
    top: 0;
  }
}

h1 {
  display: block;

  line-height: 1;
  white-space: nowrap;
  will-change: transform;

}

.container {
  position: relative;
}

@each $cat, $palette in $page-types {
  .palette-#{$cat} {
    @include themify-rule('background-color', 'bg', 'cat-'+$palette);
    @include themify-rule('color', 'text', 'cat-'+$palette);

    html[data-theme='dark'] & {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette+'-dark');
      @include themify-rule('color', 'text', 'cat-'+$palette+'-dark');
    }

    html[data-cat='#{$cat}'] & {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette+'-dark');
      @include themify-rule('color', 'text', 'cat-'+$palette+'-dark');
    }

    html[data-cat='#{$cat}'][data-theme='dark'] & {
      @include themify-rule('background-color', 'bg', 'cat-'+$palette);
      @include themify-rule('color', 'text', 'cat-'+$palette);
    }
  }
}

</style>
