<template>
  <span class="icon-wrapper">
    <svg
      class="ring"
      viewBox="0 0 40 40"
    >
      <circle
        fill="transparent"
        r="18"
        cx="20"
        cy="20"
      />
    </svg>
    <slot />
  </span>
</template>

<script>
export default {
  name: 'IconWrapper'
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  position: relative;

  display: inline-block;

  width: rem(40px);
  height: rem(40px);

  border-radius: 50%;

  box-shadow: inset 0 0 0 2px rgba($c-white, $alpha: 0.3);
  cursor: pointer;

}

.icon {
  position: absolute;
  top: 25%;
  left: 25%;

  width: 50%;
  height: 50%;
}

.ring {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  stroke: currentColor;
  stroke-width: 2;

  html.desktop & {
    stroke-dasharray: 131;
    stroke-dashoffset: 131;

    stroke-width: 3;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 0.3s;
  }

  html.desktop .icon-wrapper:hover &,
  html.desktop a:hover & {
    stroke-dashoffset: 0;
  }
}
</style>
