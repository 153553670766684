<template>
  <div class="wrapper">
    <VideoPlayer ref="video" class="video" :video="url"/>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import VideoPlayer from '@/molecules/VideoPlayer'

export default {
  name: 'PIPVideoWrapper',
  props: {
    url: {
      type: String,
      required: true
    }
  },
  components: { VideoPlayer },
  async mounted () {
    await this.$nextTick()
    this.createTl()

    this.$events.on('open-modal', this.onModalOpen)
  },
  beforeDestroy () {
    this.tl && this.tl.kill()

    this.$events.off('open-modal', this.onModalOpen)
  },
  methods: {
    createTl () {
      this.isPIP = false
      this.tl = ScrollTrigger.create({
        trigger: this.$el,
        start: 'top bottom',
        end: 'top top-=150',
        onToggle: self => {
          if (self.isActive) {
            // enter in viewport
            if (this.isPIP) {
              this.isPIP = false
              gsap.to(this.$refs.video.$el, {
                opacity: 0,
                x: 20,
                duration: 0.2,
                onComplete: () => {
                  this.isPIP = false
                  this.$refs.video.$el.classList.toggle('pip', false)
                  gsap.to(this.$refs.video.$el, { opacity: 1, x: 0, duration: 0.4 })
                }
              })
            }
          } else {
            if (!this.isPIP) {
              this.isPIP = true
              const tl = gsap.timeline()

              tl.to(this.$refs.video.$el, {
                opacity: 0,
                x: 20,
                duration: 0.2
              })
              tl.add(() => (this.$refs.video.$el.classList.toggle('pip', true)))
              tl.to(this.$refs.video.$el, {
                x: 0,
                opacity: 1,
                duration: 0.4
              })
            }
          }
        }
      })
    },
    onModalOpen (isOpen) {
      if (isOpen) {
        this.$refs.video.pause()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$pip-w-mobile: 150px;
$pip-w-desktop: 300px;
$ratio: 9/16;

.wrapper {
  position: relative;

  width: 100%;
  height: 0;

  padding-bottom: 56.25%;
}

.video:not(.pip) {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.video.pip {
  position: fixed;
  z-index: $z-pip-video;
  right: rem(16px);

  width: rem($pip-w-mobile);
  height: rem($pip-w-mobile*$ratio);

  @screen beforeLg {
    top: rem(60px);
  }

  @screen lg {
    bottom: rem(16px);

    width: rem($pip-w-desktop);
    height: rem($pip-w-desktop*$ratio);
  }
}
</style>
