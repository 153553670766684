function modulate (index, length) {
  return (index + length) % length
}
export default {
  props: {
    slides: { type: Array, required: true },
    loop: { type: Boolean },
    initialIndex: { type: Number, default: 0 },
    sliceLength: { type: Number, default: 1 },
    sliceOffset: { type: Number, default: 0 }
  },
  data () {
    const sliceStart = this.initialIndex - this.sliceOffset
    const currentSlice = []
    for (let i = 0; i < this.sliceLength; i++) {
      const index = modulate(sliceStart + i, this.slides.length)

      currentSlice.push({
        id: Math.random() + Date.now(),
        data: this.slides[index],
        realIndex: index
      })
    }
    return {
      currentSlice,
      direction: 1,
      lastDiff: 0,
      currentIndex: this.initialIndex
    }
  },
  watch: {
    currentIndex: {
      immediate: true,
      handler (currentIndex, oldIndex = 0) {
        if (currentIndex === oldIndex) return
        const sideIndex = this.direction < 0 ? this.currentSlice[0].realIndex : this.currentSlice[this.sliceLength - 1].realIndex
        for (let i = 1; i <= Math.abs(this.lastDiff); i++) {
          const indexToAdd = modulate(sideIndex + this.direction * i, this.slides.length)
          const newSlide = {
            id: Math.random() + Date.now() + i,
            data: this.slides[indexToAdd],
            realIndex: indexToAdd
          }
          if (this.direction < 0) {
            this.currentSlice.unshift(newSlide)
            this.currentSlice.pop()
          } else {
            this.currentSlice.push(newSlide)
            this.currentSlice.shift()
          }
        }
      }
    }
  },
  methods: {
    goTo (index) {
      const diff = Math.abs(index - this.currentIndex)
      if (diff === 0) return
      this.direction = index < this.currentIndex ? -1 : 1
      this.lastDiff = this.direction * diff
      this.currentIndex = modulate(this.currentIndex + this.lastDiff, this.slides.length)
    },
    next () {
      const newIndex = this.currentIndex + 1
      if (!this.loop && newIndex >= this.slides.length) return
      this.goTo(newIndex)
    },
    prev () {
      const newIndex = this.currentIndex - 1
      if (!this.loop && newIndex < 0) return
      this.goTo(this.currentIndex - 1)
    }
  }
}
