<template>
  <div class="share-wrapper beforeMd:text-right">
    <span class="beforeMd:hidden mx-2">
      Share
    </span>
    <IconWrapper class="mx-2" @click.native="onShare('fb')">
      <IconFacebook class="icon" />
    </IconWrapper>
    <IconWrapper class="mx-2" @click.native="onShare('tw')">
      <IconTwitter class="icon" />
    </IconWrapper>
  </div>
</template>

<script>
import IconWrapper from '@/atoms/IconWrapper'
import IconFacebook from '@/assets/svg/icon-facebook.svg'
import IconTwitter from '@/assets/svg/icon-twitter.svg'

export default {
  name: 'Share',
  components: {
    IconWrapper,
    IconFacebook,
    IconTwitter
  },
  methods: {
    onShare (platform) {
      const link = platform === 'fb'
        ? `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
        : `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`

      window.open(link, 'Share', 'top=0,left=0,toolbar=0,status=0,width=500,height=500')
    }
  }
}
</script>

<style lang="scss" scoped>
.share-wrapper {
  @screen md {
    display: flex;

    align-items: center;
    justify-content: flex-end;
  }
}

span:first-child {

  @screen beforeMd {
    margin-bottom: rem(16px);

    text-align: center;
  }
}
</style>
