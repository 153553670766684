<template>
  <div
    v-if="Project"
    class="project"
    :class="{
      'pb-0': nextProject,
      'palette-publishing':  Project.category && Project.category.colorPalette === 'Publishing',
      'palette-corporate':  Project.category && Project.category.colorPalette === 'CorporateIdentity',
      'palette-labels':  Project.category && Project.category.colorPalette === 'Labels',
      'palette-packaging':  Project.category && Project.category.colorPalette === 'Packaging'
    }"
  >
    <HeroText :title="Project.title" :subtitle="Project.category ? Project.category.title : ''" />
    <div class="container info-section">
      <Badge
        v-if="$root.Settings.badges && Project.hp"
        ref="badgeHp"
        type="hp"
        :style="{transform: `translate(${badgeStyleHp[0] + (Project.badge ? 50 : 0)}%, ${badgeStyleHp[1]}%)` }"
        class="project-badge stagger hp"
      />
      <Badge
        v-if="$root.Settings.badges && Project.category && Project.badge"
        ref="badge"
        :category="Project.category.colorPalette"
        :type="Project.badge"
        :style="{transform: `translate(${badgeStyle[0]}%, ${badgeStyle[1]}%)` }"
        class="project-badge stagger text-secondary"
      />
      <div class="row">
        <div class="col-lg-6 col-xl-5 col-2xl-4 order-2 lg:order-1 mb-8 flex flex-col justify-end">
          <h3 class="mb-2 stagger">{{Project.title}}</h3>
          <h4 class="mb-8 label text-secondary stagger">{{Project.category.title}}</h4>
          <!-- <div class="row mb-8">
            <div class="col-6">
              <h4 class="label text-secondary stagger">{{$root.Settings.authorLabel}}</h4>
              <p class="stagger">{{Project.author}}</p>
            </div>
            <div class="col-12" v-if="Project.category">
              <h4 class="label text-secondary stagger">{{$root.Settings.categoryLabel}}</h4>
              <p class="stagger">{{Project.category.title}}</p>
            </div>
          </div>
          -->
          <MarkdownBlock class="stagger" :text="Project.excerpt" />
        </div>
        <div class="col-lg-6 offset-xl-1 offset-2xl-2 order-1 lg:order-2 mb-8">
          <PIPVideoWrapper v-if="Project.video" :url="Project.video" class="stagger main-video"/>
          <ResponsiveImage v-else-if="Project.image"  class="stagger main-image" :data="Project.image" />
        </div>
      </div>
    </div>
    <template v-if="Project.gallery && Project.gallery.length > 0">
      <GalleryPreview
        v-if="viewPort.width > 1023"
        :alt="`Gallery of ${Project.title}`"
        :list="Project.gallery"
      />
      <Gallery
        v-else
        class="my-8"
        stagger
        :alt="`Gallery of ${Project.title}`"
        :slides="Project.gallery"
        :sliceLength="7"
        :sliceOffset="2"
        :loop="true"
      />
    </template>

    <AccordionList :list="Project.accordion">
      <template v-slot:header="{ item }">
        <h3 class="stagger">{{getTitle(item)}}</h3>
        <div class="plus-icon stagger"></div>
      </template>
      <template v-slot:content="{ item }">
        <template v-if="item._repeaterType === 'technicalInfo'">
          <div v-if="item.printing" class="pb-8">
            <h5>{{$root.Settings.printingLabel}}</h5>
            <MarkdownBlock :text="item.printing" />
          </div>
          <div v-if="item.client" class="pb-8">
            <h5>{{$root.Settings.clientLabel}}</h5>
            <MarkdownBlock :text="item.client" />
          </div>
          <div v-if="item.editor" class="pb-8">
            <h5>{{$root.Settings.editorLabel}}</h5>
            <MarkdownBlock :text="item.editor" />
          </div>
          <div v-if="item.designer" class="pb-8">
            <h5>{{$root.Settings.designerLabel}}</h5>
            <MarkdownBlock :text="item.designer" />
          </div>
          <div v-if="item.paper" class="pb-8">
            <h5>{{$root.Settings.paperLabel}}</h5>
            <MarkdownBlock :text="item.paper" />
          </div>
          <div v-if="item.size" class="pb-8">
            <h5>{{$root.Settings.sizeLabel}}</h5>
            <MarkdownBlock :text="item.size" />
          </div>
        </template>
        <MarkdownBlock v-else class="pt-8" :text="item.text" />
      </template>
    </AccordionList>
    <div class="container mt-8">
      <div class="row">
        <div class="col-4 col-md-6">
          <router-link
            class="back inline-flex items-center"
            :to="{name: 'ProjectsList'}"
          >
            <IconWrapper>
              <IconArrow />
            </IconWrapper>
            <span class="beforeMd:hidden md:pl-4">
              {{$root.Settings.backToProjectsLabel}}
            </span>
          </router-link>
        </div>
        <div class="col-8 col-md-6">
          <Share />
        </div>
      </div>
    </div>
    <NextProject v-if="nextProject" v-bind="nextProject"/>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { Viewport, MarkdownBlock } from '@monogrid/vue-lib'
import ProjectQuery from '@/graphql/GetProject.gql'
import ShareMixin from '@/mixins/share-mixin'
import WaitLoader from '@/mixins/wait-loader'
import PIPVideoWrapper from '@/organisms/PIPVideoWrapper'
import GalleryPreview from '@/organisms/GalleryPreview'
import Gallery from '@/molecules/Gallery'
import NextProject from '@/molecules/NextProject'
import Share from '@/molecules/Share'
import Badge from '@/atoms/Badge'
import HeroText from '@/atoms/HeroText'
import ResponsiveImage from '@/atoms/ResponsiveImage'
import AccordionList from '@/atoms/AccordionList'
import IconWrapper from '@/atoms/IconWrapper'
import IconArrow from '@/assets/svg/icon-arrow.svg'

export default {
  name: 'SingleProjectPage',
  mixins: [Viewport, ShareMixin, WaitLoader(1000)],
  props: {
    slug: { type: String }
  },
  components: {
    HeroText,
    ResponsiveImage,
    MarkdownBlock,
    AccordionList,
    NextProject,
    PIPVideoWrapper,
    GalleryPreview,
    Gallery,
    IconWrapper,
    IconArrow,
    Share,
    Badge
  },
  apollo: {
    Project: {
      query: ProjectQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          slug: this.slug,
          variants: [this.$language.current === 'en' ? 'base' : this.$language.current]
        }
      },
      async result () {
        if (this.Project === null) {
          this.$router.replace({ name: 'Home' })
        }
      }
    }
  },
  data () {
    return {
      badgeStyle: [~~(20 * Math.random()), -50 + ~~(25 - 50 * Math.random())],
      badgeStyleHp: [~~(40 * Math.random()), -50 + ~~(25 - 50 * Math.random())]
    }
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  watch: {
    Project (value) {
      if (value) {
        this.$root.preloader.visible = false

        this.$events.emit('current-category', this.Project.category && this.Project.category.colorPalette
          ? this.Project.category.colorPalette
          : '')

        this.$nextTick(this.createTl)
      }
    }
  },
  computed: {
    nextProject () {
      if (!this.Project || !this.$root.Projects) return false
      const index = this.$root.Projects.findIndex(prj => prj.id === this.Project.id)
      return this.$root.Projects[(index + 1) % this.$root.Projects.length]
    }
  },
  methods: {
    getTitle (item) {
      switch (item._repeaterType) {
        case 'description':
          return this.$root.Settings.accordionDescription
        case 'technicalInfo':
          return this.$root.Settings.accordionTechnicalInfo
        default:
          return ''
      }
    },
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el.querySelector('.info-section'),
          start: 'top bottom'
        }
      })
      this.checkLoaderState()
      this.tl.fromTo(this.$el.querySelectorAll('.info-section .stagger'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, stagger: 0.1 })
    }
  },
  metaInfo () {
    if (this.Project) {
      return this.getShare({
        title: this.Project.title,
        description: this.Project.excerpt,
        image: this.Project.image
      })
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
  line-height: 1;
}

h5 {
  font-size: rem(20px);
  font-weight: bold;
}

.info-section {
  position: relative;
}

.project-badge {
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 0%;

  width: rem(80px);
  height: rem(80px);

  transform: translateY(-50%);
  @screen md {
    width: 16vw;
    height: 16vw;
  }
  @screen lg {
    bottom: 80%;
  }

  &.hp {
    color: $c-hp;
  }
}

.main-image {
  @screen lg {
    transform: translateY(-25%);
  }
}

.main-video {
  @screen lg {
    margin-top: rem(-80px);
  }
}
</style>
