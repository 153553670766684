<template>
  <div
    class="gallery mx-auto"
    :style="computedStyle"
  >
    <div
      class="slide"
      ref="slides"
      v-for="({id, data}, i) in currentSlice"
      :key="id"
      @click="changeSlide"
    >
      <ResponsiveImage
        class="image"
        :data="data"
        :role="i === 0 ? null : 'presentation'"
      />
    </div>
    <div class="status">
      <div
        ref="statusItem"
        class="status_item"
        :class="{
          'previous': i < currentIndex,
          'active': i === currentIndex
        }"
        v-for="(_, i) in slides"
        :key="i"
      />
    </div>
  </div>
</template>

<script>
import { Viewport } from '@monogrid/vue-lib'
import { gsap } from 'gsap'
import WaitLoader from '@/mixins/wait-loader'
import GalleryMixin from '@/mixins/gallery-mixin'
import ResponsiveImage from '@/atoms/ResponsiveImage'

export default {
  name: 'Gallery',
  mixins: [GalleryMixin, Viewport, WaitLoader(1000)],
  components: { ResponsiveImage },
  props: {
    stagger: { type: Boolean, default: false }
  },
  data () {
    return {
      computedStyle: {}
    }
  },
  watch: {
    viewPort: {
      immediate: true,
      handler () {
        this.onResize()
      }
    },
    currentSlice: {
      immediate: true,
      handler () {
        this.$nextTick(this.animate)
      }
    }
  },
  async mounted () {
    await this.$nextTick()
    this.stagger && this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom'
        }
      })
      this.checkLoaderState()
      this.tl.fromTo(this.$el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 })
    },
    animate () {
      this.$el.querySelectorAll('.slide').forEach((slide, i) => {
        const position = i - this.sliceOffset
        let opacity = 1
        let y = -10 * position
        const z = -10 * position

        if (position < 0) {
          y = 20
          opacity = 0
        } else if (position === this.$refs.slides.length - (this.sliceOffset + 1)) {
          y += 20
          opacity = 0
        }

        gsap.to(slide, { y, z, opacity, zIndex: this.$refs.slides.length - i })
      })
    },
    changeSlide (e) {
      e.clientX < window.innerWidth / 2 ? this.prev() : this.next()
    },
    async onResize () {
      const header = 60
      const footer = 60
      const safeAreaW = this.viewPort.width - 32
      const safeAreaH = this.viewPort.height - header - footer
      const width = (safeAreaH * 16) / 9

      const safeWidth = Math.min(width, safeAreaW)
      const safeHeight = (safeWidth * safeAreaH) / width

      this.computedStyle = {
        width: `${~~safeWidth}px`,
        height: `${~~safeHeight}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;

  perspective: 200px;
  perspective-origin: 50% 0%;
}

.slide {
  position: absolute;

  z-index: 0;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;

  opacity: 0;
}

.image {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.status {
  position: absolute;
  z-index: 99;
  top: rem(20px);
  left: 50%;

  display: flex;

  width: 90%;

  justify-content: center;

  transform: translateX(-50%) translateZ(10px);
}

.status_item {
  position: relative;

  max-width: rem(45px);
  height: rem(2px);

  flex: 1 1 auto;

  border-radius: rem(3px);

  margin: rem(0 5px);

  background: rgba($c-white, $alpha: 0.2);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: $c-white;
    content: '';
    transform: scaleX(0);
    transform-origin: left center;
  }

  &.previous,
  &.active {
    &::after {
      transform: scaleX(1);
    }
  }

  &.active {
    &::after {
      transition: transform 0.5s;
    }
  }

}
</style>
