<template>
  <div class="container" @click="onShowModal">
    <div
      v-for="(image, i) in list.slice(0, 5)"
      :key="i"
      :class="`image-${i}`"
    >
      <ResponsiveImage
        :data="image"
        :width="maxWidths[i]"
        :alt="alt"
        :role="i=== 0 ? null : 'presentation'"
      />
      <div
        v-if="i===0"
        class="wrapper-plus bg-before-secondary"
      >
        <div class="plus-icon" />
      </div>
    </div>
    <portal to="modal">
      <div class="modal" >
        <div
          class="modal-bg bg-before-secondary"
          :style="{
            'margin-top': `${this.circleY}px`,
            'margin-left': `${this.circleX}px`
          }"
        />
        <Gallery
          class="modal-gallery"
          :alt="alt"
          :slides="list"
          :sliceLength="7"
          :sliceOffset="2"
          :loop="true"
        />
        <Cta
          class="close-modal"
          @click.native="showModal=false"
        >
          CLOSE
        </Cta>
      </div>
    </portal>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import Gallery from '@/molecules/Gallery'
import ResponsiveImage from '@/atoms/ResponsiveImage'
import Cta from '@/atoms/Cta'

export default {
  name: 'GalleryPreview',
  components: { Cta, Gallery, ResponsiveImage },
  props: {
    alt: { type: String, default: 'Gallery' },
    list: { type: Array }
  },
  data () {
    return {
      showModal: false,
      circleX: 0,
      circleY: 0,
      maxWidths: [1200, 300, 425, 550, 300]
    }
  },
  async mounted () {
    await this.$nextTick()
    this.createTl()
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
    this.tlModal && this.tlModal.kill()
  },
  watch: {
    showModal (show) {
      this.$events.emit('toggle-scroll', !!show)
      this.tlModal.tweenTo(show ? 'show' : 'hide')
    }
  },
  methods: {
    onShowModal (e) {
      this.circleX = e.clientX
      this.circleY = e.clientY
      this.showModal = true

      this.$events.emit('open-modal', true)
    },
    createTl () {
      this.tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1
        }
      })
      if (this.$el.querySelector('.image-1')) {
        this.tl.fromTo(this.$el.querySelector('.image-1'), { y: 100 }, { y: 0, duration: 0.2 })
      }
      if (this.$el.querySelector('.image-2')) {
        this.tl.fromTo(this.$el.querySelector('.image-2'), { y: -60 }, { y: 20, duration: 0.2 }, '<')
      }
      if (this.$el.querySelector('.image-3')) {
        this.tl.fromTo(this.$el.querySelector('.image-3'), { y: -50 }, { y: 40, duration: 0.2 }, '<')
      }
      if (this.$el.querySelector('.image-4')) {
        this.tl.fromTo(this.$el.querySelector('.image-4'), { y: 50 }, { y: -20, duration: 0.2 }, '<')
      }

      this.tlModal = gsap.timeline({ paused: true })
      this.tlModal.addLabel('hide')
      this.tlModal.fromTo('.modal', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.1 })
      this.tlModal.fromTo('.modal .modal-bg', { scale: 0 }, {
        scale: () => (2 * Math.max(1, window.innerHeight / window.innerWidth)),
        duration: 0.6,
        ease: 'power1.out'
      }, 0)
      this.tlModal.fromTo('.modal .gallery', { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.2)
      this.tlModal.fromTo('.modal .close-modal', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3 })
      this.tlModal.addLabel('show')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;

  padding: rem(80px 0);

  margin: rem(80px) auto;
}

.close-modal {
  position: absolute;
  bottom: rem(32px);
  left: 50%;

  transform: translateX(-50%);
}

.modal-gallery {
  will-change: opacity;
}

.wrapper-plus {
  position: absolute;
  top: 50%;
  left: 50%;

  width: rem(80px);
  height: rem(80px);

  opacity: 0;

  transform: translate(-50%, -50%);
  transition: opacity 0.2s;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 50%;

    background: #f00;
    content: '';
    transform: scale(0);
    transition: transform 0.3s;
  }

  .plus-icon {
    right: 50%;

    margin-right: rem(-15px);

    transition: transform 0.3s;
  }

  .image-0:hover & {
    opacity: 1;

    &::before {
      transform: scale(1);
    }

    .plus-icon {
      transform: rotate(90deg);
    }
  }
}

.image-0 {
  position: relative;

  display: block;
  overflow: hidden;

  width: 90%;

  margin: 0 auto;

  cursor: pointer;

  img {
    width: 100%;

    transition: transform 0.5s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.image-1,
.image-2,
.image-3,
.image-4 {
  position: absolute;

  will-change: transform;
}

.image-1 {
  top: 0;
  left: 7%;

  width: 18%;
}

.image-2 {
  top: rem(40px);
  right: -5%;

  width: 35%;
}

.image-3 {
  bottom: 0%;
  left: -5%;

  width: 45%;
}

.image-4 {
  right: 15%;

  bottom: rem(40px);

  width: 25%;
}
</style>
